import { useContext, useState } from "react";
import logo from "../../assets/images/sideLogo.png";
import globalMenuIcon from "../../assets/svgs/globalMenuIcon.svg";
import cancel from "../../assets/svgs/cancel.svg";
import Avatar from "react-avatar";
import "./globalMenu.css";
import { useHistory, useLocation } from "react-router";
import { Button } from "../button/Button";
import { TENECE_SUPPORT_URL, TRANSCRIPT_APP_URL } from "../../utils/constants";
import { StudentProfileContext } from "..";
import { LinkIcon } from "../../assets/svgs";
import ChristmasLogo from "../../assets/images/christmasLogo.png";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";

const GlobalMenu = ({
	title = "Components",
	openSide = () => {},
	setSignOutModal,
	userName,
	isLanding
}) => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	const [menu, setMenu] = useState(false);

	const linkOptions = [
		{
			name: "Home",
			route: "/"
		},
		{
			name: "Check Admission Status",
			route: "/prospective_students"
		},
		{
			name: "Sundry payments",
			route: "/sundry/all"
		},
		{
			name: "E-Bursary",
			route: "/e_bursary"
		}

	];

	const data = useContext(StudentProfileContext);

	return (
		<section className="red-global-menu">
			<div className="d-flex align-items-center">
				{isLanding !== "unAuthenticated" ? (
					<button className="red-hamburger-menu" onClick={openSide}>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				) : (
					<button
						className="red-hamburger-menu responsive-hamburger-menu"
						onClick={() => setMenu(true)}
					>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				)}
				<div className="red-global-logo">
					{checkIfChristmasPeriod() ? (
						<>
							<img src={ChristmasLogo} alt="COOU logo" />
							<img src={ChristmasLogo} alt="COOU logo" />
						</>
					) : (
						<>
							<img src={logo} alt="COOU logo" />
							<img src={logo} alt="COOU logo" />
						</>
					)}
				</div>
				{isLanding === "unAuthenticated" ? (
					<div
						className={`red-current-module global-landing-page-link-container ${
							menu && `retract-navigation`
						}`}
					>
						<button
							onClick={() => setMenu(false)}
							className="cancel-button"
						>
							<img src={cancel} alt="" />
						</button>

						{linkOptions.map((link, i) => (
							<button
								key={i}
								className={`global-landing-page-links ${
									pathname === link.route &&
									"global-landing-page-links-active"
								}`}
								onClick={() => push(link.route)}
							>
								{link.name}
							</button>
						))}
						<a href={TRANSCRIPT_APP_URL}>
							<button
								className={`global-landing-page-links `}
								onClick={() => push("#")}
							>
								eTranscript
							</button>
						</a>
					</div>
				) : (
					<div className="red-current-module">{title}</div>
				)}
			</div>
			{isLanding !== "unAuthenticated" ? (
				<div className="d-flex dropdown">
					<section
						id="navDrop"
						data-toggle="dropdown"
						role="button"
						data-cy="open_avatat"
					>
						<Avatar
							name={userName ?? "Guest"}
							round
							size="32"
							className="global-avatar"
							maxInitials={2}
							src={data?.profileData?.personalData?.passport}
						/>
					</section>
					<section className="dropdown-menu res-role">
						<h3 className="res-user">Account</h3>
						<div className="res-wrapper">
							<div className=" res-roles w-100 d-flex justify-content-start align-items-center">
								<Avatar
									name={userName ?? "Guest"}
									round
									size="32"
									className="global-avatar mr-2"
									maxInitials={2}
									src={
										data?.profileData?.personalData
											?.passport
									}
								/>
								<div>
									<h3 className="res-profile-name">
										{userName?.toUpperCase() ?? "guest"}
									</h3>
									<h3 className="res-profile-detail mt-1">
										{
											data?.profileData?.programmeDetail
												?.level
										}
									</h3>
								</div>
							</div>

							<div className="res-roles mx-0 w-100">
								<div
									onClick={() => push("/profile")}
									className="mb-0 d-flex justify-content-between mt-3 ml-0"
								>
									Manage Profile
									<LinkIcon />
								</div>
							</div>
						</div>

						<section className="res-wrapper">
							<h3 className="res-user">Settings</h3>
							<div className="res-roles">
								<div
									className="d-flex align-items-center"
									onClick={() => push("/change_password")}
								>
									<p className="mb-0 mx-0">Change Password</p>
								</div>
							</div>
							<div className="res-roles">
								<div
									onClick={() =>
										window.open(
											`${TENECE_SUPPORT_URL}`,
											"_blank"
										)
									}
								>
									<p className="mb-0 mx-0">Contact Support</p>
								</div>
							</div>
						</section>

						<section>
							<p
								className="mb-0 res-role-txt mt-3"
								onClick={() => setSignOutModal(true)}
							>
								Logout
							</p>
						</section>
					</section>
				</div>
			) : (
				<Button
					onClick={() => push("/login")}
					data-cy="default"
					buttonClass="primary"
					label="Login"
				/>
			)}
		</section>
	);
};

export { GlobalMenu };
